import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class CommonBindingDataService {
  accesses: any;
  manualBookingData: any = {};
  rideType: any;
  carddetails: any = {};
  selectedAppointmentType: any;
  editButton: any;
  clientUpdatedButton:any
  calendarButton: any;
  statusButton: any;
  statusActiveButton: any;
  detailsButton: any;
  bellButton: any;
  detailsButton2: any;
  resendNotificationButton: any;
  assignButton: any;
  reAssignButton: any;
  cancelButton: any;
  attachmentButton: any;
  reRequestButton: any;
  public redrawSidebar: Subject<any> = new Subject<any>();
  callButton: any;
  videoCallButton: any;
  private siblingMsg = new Subject<string>();
  private filterMsg = new Subject<any>();
  unAssignButton: any;
  reminderButton: any;
  downloadButton: any;
  deleteButton: any;
  screeningTabButton: any;
  viewMapButton: any;
  cancelReqButton: any;
  cancelDispatchRequestButton: any;
  approveButton: any;
  rejectButton: any;
  minuteArray: number[] = [0, 15, 30, 45];
  currentDate = new Date();
  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    this.initialiseButtons();
  }

  public getMessage(): Observable<string> {
    return this.siblingMsg.asObservable();
  }

  public updateMessage(message: string): void {
    this.siblingMsg.next(message);
  }

  public getPendingFilter(): Observable<string> {
    return this.filterMsg.asObservable();
  }

  public updatePendingFilter(message: string): void {
    this.filterMsg.next(message);
  }

  getServiceType(): Observable<any> {
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      return of(this.storageService.getItem(AppSettings.SERVICE_TYPE));
    } else {
      return this.restApiService.get('', '/secure/service-types?clientType=WEB', 'page-center');
    }
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }

  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  getBookLaterMinTime(serviceType, subcode) {
    const extraTime = this.getSpecificTimeout(serviceType, subcode);
    return new Date((new Date).getTime() + extraTime);
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }


  getTheDayUTC(time: number, start: string) {
    const tz = moment().utcOffset();
    if (start === 'Start') {
      return moment(time).startOf('day').utc().valueOf() + (tz * 60000);
    } else {
      return moment(time).endOf('day').utc().valueOf() + (tz * 60000);
    }
  }

  toTimezoneFormat(date, timeZone, formate = 'MMM DD, YYYY hh:mm A z') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateTimeStringFormat(date, timeZone, formate = 'MM/DD/YYYY hh:mm A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateTimeStringFormatYr(date, timeZone, formate = 'MMM/DD, hh:mm A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateTimeStringFormatStr(date, timeZone, formate = 'MMM DD, YYYY hh:mm A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateStringFormat(date, timeZone, formate = 'MM/DD/YYYY') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateFormat(date, timeZone, formate = 'MMM DD, YYYY') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneAbbr(date, timeZone, formate = 'z') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneTimeFormat(date, timeZone, formate = 'hh:mm A (z)') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }
  toTimezoneTimeFormatWithout(date, timeZone, formate = 'hh:mm A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneTimeFormatWithoutForReassign(date, timeZone, formate = 'MM/DD/YYYY hh:mm A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateTimeFormat(date, timeZone, formate = 'MM/DD/YYYY, hh:mm:ss A (z)') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  toTimezoneDateTimeFormatWithoutAbbr(date, timeZone, formate = 'MM/DD/YYYY, hh:mm:ss A') {
    const timeZoneDate = moment.tz(date, timeZone).format(formate);
    return timeZoneDate;
  }

  addLapseTimeInEndDate(endDate) {
    return endDate + AppSettings.twentyThreeFiftyNineLapse;
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }

  getServiceTypeKey(id) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          serviceTypeId = element.serviceType;
        }
      });
    }
    return serviceTypeId;
  }

  getTenantConfigValue(configKey) {
    let tenantConfig: any;
    tenantConfig = this.storageService.getItem(AppSettings.TENANT_CONFIG);
    tenantConfig = tenantConfig === null ? [] : tenantConfig;
    for (let index = 0; index < tenantConfig.length; index++) {
      if (tenantConfig[index].configKey === configKey) {
        return tenantConfig[index].configValue;
      }
    }
    return null;
  }

  getDispatchTypeConfigValue(configKey) {
    let tenantConfig: any;
    tenantConfig = this.storageService.getItem(AppSettings.TENANT_CONFIG);
    tenantConfig = tenantConfig === null ? [] : tenantConfig;
    for (let index = 0; index < tenantConfig.length; index++) {
      if (tenantConfig[index].configKey === configKey) {
        return tenantConfig[index].configValue;
      }
    }
    return null;
  }

  getServiceTypeCodeBy(id) {
    let serviceType, code;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          code = element.code;
        }
      });
    }
    return code;
  }

  getServiceTypeByCode(code) {
    let serviceType;
    const serviceTypeByCode = [];
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.code === code) {
          serviceTypeByCode.push(element);
        }
      });
    }
    return serviceTypeByCode;
  }

  getServiceTypeId(key) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.serviceType === key) {
          serviceTypeId = element.id;
        }
      });
    }
    return serviceTypeId;
  }

  removeGivenServiceTypefromList(key, serviceTypeList?) {
    let serviceTypes;
    if (serviceTypeList) {
      serviceTypes = serviceTypeList;
    } else {
      serviceTypes = this.storageService.getItem(AppSettings.SERVICE_TYPE);
    }
    serviceTypes.forEach((item, index) => {
      if (key === item.subCode) {
        serviceTypes.splice(index, 1);
      }
    });
    return serviceTypes;
  }

  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  numberWithDes(phone) {
    if (phone) {
      let newVal = phone.replace(/\D/g, '');
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length === 3) {
        newVal = newVal.replace(/^(\d{0,3})/, '$1');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      } else if (newVal.length <= 11) {
        newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3-$4');
      } else if (newVal.length <= 12) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3-$4');
      } else if (newVal.length <= 13) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3-$4');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      }
      return newVal;
    }
  }

  getServiceTypeOnLogin(): Observable<any> {
    return this.restApiService.get('', '/secure/service-types?clientType=WEB', 'page-center');
  }

  getDispatchServiceTypes(): Observable<any> {
    return this.restApiService.get('', '/secure/service-types?code=dispatch', 'page-center');
  }

  getProviderVehicles(): Observable<any> {
    return this.restApiService.get('', '/secure/provider-vehicles/list', 'page-center');
  }

  getProviderTeamMembers(): Observable<any> {
    return this.restApiService.get('', '/secure/drivers/members/list', 'page-center');
  }

  getServiceRegions(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/list/data', 'page-center');
  }


  getDispatchServiceTypesByProviderID(id): Observable<any> {
    const results = this.restApiService.get('', '/secure/dispatch-type?providerId=' + id, 'page-center');
    return results;
  }

  getProviderVehiclesByProviderID(id): Observable<any> {
    return this.restApiService.get('', '/secure/provider-vehicles/list?providerId=' + id, 'page-center');
  }

  getProviderTeamMembersByProviderID(id, teamId?: string, date?): Observable<any> {
    const dateParams = date ? `&date=${date}` : '';
    if (teamId === null || teamId === undefined) {
      return this.restApiService.get('', '/secure/drivers/members/list?providerId=' + id +
        '&permenant=' + 'no' + dateParams, 'page-center');
    } else {
      return this.restApiService.get('', '/secure/drivers/members/list?providerId=' + id +
        '&teamId=' + teamId + dateParams, 'page-center');
    }
  }

  getServiceRegionsByProviderID(id): Observable<any> {
    return this.restApiService.get('', '/secure/service-region?providerId=' + id, 'page-center');
  }
  getUserRoles(): Observable<any> {
    return this.restApiService.get('', '/secure/tags/user_role', 'page-center');
  }

  getLoginTypes(): Observable<any> {
    return this.restApiService.get('', '/secure/tags/login_type', 'page-center');
  }

  getAllVehicleType(): Observable<any> {
    return this.restApiService.get('', `/secure/vehicle-types/list/data`, 'page-center');
  }

  getDocumentType(): Observable<any> {
    return this.restApiService.get('', '/secure/driver-document-types', 'page-center');
  }

  getProviderDocumentType(): Observable<any> {
    return this.restApiService.get('', '/secure/provider-document-types', 'page-center');
  }

  getVehicleType(): Observable<any> {
    return this.restApiService.get('', '/secure/vehicle-types', 'page-center');
  }

  getDriver(): Observable<any> {
    return this.restApiService.get('', '/secure/drivers/list/data', 'page-center');
  }

  getVehiclesType(vehicleTypeId, serviceTypeIds): Observable<any> {
    return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId +
      '&serviceTypeIds=' + serviceTypeIds, 'page-center');
  }

  getVehiclesByCompanyAndVehicleType(vehicleTypeId, companyId?: any): Observable<any> {
    if (companyId !== null && companyId !== undefined) {
      return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + companyId, 'page-center');
    } else {
      return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=', 'page-center');
    }
  }

  getVehicleDocumentType(): Observable<any> {
    return this.restApiService.get('', '/secure/vehicle-document-types', 'page-center');
  }

  getCountries(): Observable<any> {
    return this.restApiService.get('', '/secure/countries', 'page-center');
  }

  getState(id): Observable<any> {
    return this.restApiService.get('', `/secure/countries/${id}/states`, 'page-center');
  }

  getCitybyCountries(code): Observable<any> {
    return this.restApiService.get('', '/secure/countries/' + code + '/citiesbycountry', 'page-center');
  }

  getUsCity(data): Observable<any> {
    return this.restApiService.get('', `/secure/countries/US/citiesbycountry?searchText=${data}`, 'page-center');
  }

  getCitybyState(code): Observable<any> {
    return this.restApiService.get('', '/secure/countries/' + code + '/citiesbystate', 'page-center');
  }

  getCitybyZipCode(zipCode, countryCode?: any): Observable<any> {
    return this.restApiService.get('', '/secure/countries-city?zipCode=' + zipCode + '&countryCode=' + countryCode, 'page-center');
  }

  getDispatcher(data, code): Observable<any> {
    return this.restApiService.get('', `/secure/dispatchers/data/search?searchText=${data}&roleCode=${code}`, 'page-center');
  }

  commentReply(data, id): Observable<any> {
    return this.restApiService.post('', `/secure/booking/trip/${id}/comment-reply`, data, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  readNotifications(notificationId, readStatus): Observable<any> {
    return this.restApiService.put('', `/secure/notification/${notificationId}/status`, readStatus, 'page-center');
  }

  addCorporateBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/corporate`, data, 'page-center');
  }

  addHotelBooking(data, serviceType, hotelId): Observable<any> {
    return this.restApiService.post('', `/secure/hotels/${hotelId}/booking/${serviceType}`, data, 'page-center');
  }

  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  getTiming() {
    return this.restApiService.get('', '/secure/settings/timeouts', 'page-center');
  }

  getRolesList() {
    return this.restApiService.get('', '/secure/tenants/organising-setting/list', 'page-center');
  }

  updateRole(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/organising-setting/update', data);
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' +
        serviceType + '?vehicleTypeId=' + vehicleTypeId + '&passengerId=' + passengerId;
      return this.restApiService.get('', url, 'page-center');
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get('', url, 'page-center');
    }
  }

  getPricingDetails(data) {
    return this.restApiService.get('', '/secure/packages/' + data, 'page-center');
  }

  setPackages(data, id?, corporateId?): Observable<any> {
    return this.restApiService.put('', `/secure/packages/${id}/partners/${corporateId}`, data, 'page-center');
  }

  getRentalPricingDetails(params) {
    return this.restApiService.get('', '/secure/packages/rental/partners/' + params, 'page-center');
  }

  updateRentalPackage(data, corporateId) {
    return this.restApiService.post('', `/secure/packages/rental/partners/${corporateId}`, data, 'page-center');
  }

  addPricingPackages(data, id?: null) {
    if (id !== undefined && id !== null && id !== '') {
      return this.restApiService.put('', '/secure/packages/' + id + '/chauffeur', data, 'page-center');
    } else {
      return this.restApiService.post('', '/secure/packages', data, 'page-center');
    }
  }

  updateCorporatePricingPackages(data, id: null, corporateId) {
    return this.restApiService.put('', '/secure/packages/' + id + '/partners/' + corporateId, data, 'page-center');
  }

  setRoute(data, corporateId?): Observable<any> {
    return this.restApiService.put('', `/secure/packages/routes/destinations/rates/partners/${corporateId}`, data, 'page-center');
  }

  getRoutingDetails(routeId, partnerId) {
    return this.restApiService.get('', `/secure/packages/routes/${routeId}/partners/${partnerId}`, 'page-center');
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/taxi`, data, 'page-center');
  }

  getHotels(): Observable<any> {
    return this.restApiService.get('', `/secure/hotels`, 'page-center');
  }

  assignDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/assign-driver', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/verification-code');
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/booking/' + id, 'page-center');
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  securityCheck(data, tripid): Observable<any> {
    return this.restApiService.post('', '/secure/bookings/' + tripid + '/security-check', data);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  rentalEndTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/bookings/' + tripid + '/end-rental-booking', data);
  }

  extendPackage(tripid): Observable<any> {
    return this.restApiService.put('', '/secure/bookings/' + tripid + '/extend-package');
  }

  getDriverCurrentLocation(driverId): Observable<any> {
    return this.restApiService.get('', '/secure/drivers/' + driverId + '/location', 'page-center');
  }

  getTagsList(tagkey): Observable<any> {
    return this.restApiService.get('tagList', `/secure/tags/${tagkey}`, 'page-center');
  }

  getPharmaciesList(): Observable<any> {
    return this.restApiService.get('tagList', `/secure/pharmacies/list`, 'page-center');
  }

  getMarketsList(tagkey): Observable<any> {
    return this.restApiService.get('tagList', `/secure/tenants/${tagkey}`, 'page-center');
  }

  getTimezoneList(): Observable<any> {
    return this.restApiService.get('timezone', `/secure/timezone/list`, 'page-center');
  }  // {basepath}/api/T001/secure/timezone/list?searchText=

  getTimezonesList(): Observable<any> {
    return this.restApiService.get('timezone', `/secure/timezones/list`, 'page-center');
  }

  getPassengersList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?searchText=' + searchString, 'page-center');
  }

  dispatchGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  contactGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  teamGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  teamMemberGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  shiftPlanGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  manageShiftGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  hasAccessPresent(accessCode) {
    const menuList = this.getSelectedMenuAccessesList();
    const hasAccess = menuList.find((elm) => {
      return elm.accessCode === accessCode;
    });

    if (hasAccess !== undefined && hasAccess !== null) {
      return true;
    }
    return false;
  }

  getSubmenuListByMenuName(menuName) {
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (menu.name === menuName) {
        return menu.subMenuList;
      }
    }
    return [];
  }

  getSelectedMenuAccessesList(returnObj?) {
    const location = window.location.href;
    const urlArray = location.split('#');
    const displayUrls = urlArray[urlArray.length - 1];
    const displayUrl = displayUrls.split('?')[0];
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (menu.displayUrl !== '' && (displayUrl.indexOf(menu.displayUrl) >= 0)) {
        if (returnObj) {
          return menu;
        }
        return menu.accesses;
      } else {
        if (menu.subMenuList && menu.subMenuList.length) {
          const subMenuAccess = this.checkSubmenus(menu.subMenuList, displayUrl, returnObj);
          if (subMenuAccess !== undefined && subMenuAccess !== null) {
            return subMenuAccess;
          }
        }
      }
    }
    return [];
  }

  hasAccessPresentHeader(accessCode) {
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    let menu;
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].accessGroupCode === 'MANAGER_NOTES' || menuItems[i].accessGroupCode === 'OPEN_SHIFT') {
        menu = menuItems[i];
      }
    }
    let accessPresent;
    if (menu) {
      accessPresent = menu.accesses.find((elm) => {
        return elm.accessCode === accessCode;
      });
    }
    if (accessPresent !== undefined && accessPresent !== null) {
      return true;
    }
    return false;
  }

  checkSubmenus(subMenuList, menuName, returnObj?) {
    if (subMenuList !== undefined && subMenuList !== null) {
      for (let menuIndex = 0; menuIndex < subMenuList.length; menuIndex++) {
        const menu = subMenuList[menuIndex];
        if (menu.displayUrl !== '' && (menuName.indexOf(menu.displayUrl) >= 0)) {
          if (returnObj) {
            return menu;
          }
          return menu.accesses;
        } else {
          if (menu.subMenuList && menu.subMenuList.length) {
            const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
            if (subMenuAccess !== undefined && subMenuAccess !== null) {
              return subMenuAccess;
            }
          }
        }
      }
      return null;
    }
  }

  checkAndSetSelectedMenu(routeUrl) {
    if (!this.accesses) {
      this.accesses = this.storageService.getItem(AppSettings.ACCESS_MENU);
    }
    for (let i = 0; i < this.accesses.length; i++) {
      if (this.compareUrl(this.accesses[i].displayUrl, routeUrl)) {
        this.storageService.setItem('selectedMenu', this.accesses[i].accessGroupCode);
        return true;
      } else {
        if (this.accesses[i].subMenuList.length) {
          for (let j = 0; j < this.accesses[i].subMenuList.length; j++) {
            if (this.compareUrl(this.accesses[i].subMenuList[j].displayUrl, routeUrl)) {
              this.storageService.setItem('selectedMenu', this.accesses[i].subMenuList[j].accessGroupCode);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  compareUrl(srcUrl, routeUrl) {
    return srcUrl ? srcUrl.toLowerCase().indexOf(routeUrl.toLowerCase()) >= 0 : false;
  }

  getCities(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/cities', 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('', '/secure/tour/' + tourId, 'page-center');
  }

  getDropDownData(list, key, value) {
    return (list !== null && list !== undefined) ?
      list.map(item => ({ label: item[key], value: item[value] })) : [];
  }

  getDrivers(vehicleTypeId, tourId?, search?): Observable<any> {
    const searchData = search ? search : '';
    return this.restApiService.get('', `/secure/drivers/assign-trip/list/` + vehicleTypeId + '?tourId=' +
      tourId + '&searchText=' + searchData + '&limit=10&offset=0', 'page-center');
  }

  driverAssigned(tourId, driverId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/assign-driver/' + tourId + '/' + driverId, 'page-center');
  }

  driverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/driver/ontheway/' + tourId, 'page-center');
  }

  driverArrived(tripId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + tripId + '/arrived', 'page-center');
  }

  OnTrip(tripId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripId + '/start-trip', data, 'page-center');
  }

  dropTrip(data): Observable<any> {
    return this.restApiService.post('', '/secure/tour/drop', data, 'page-center');
  }

  rideShareDriverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/driver/on-the-way', 'page-center');
  }

  rideShareDriverArrived(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/driver/arrived', 'page-center');
  }

  rideShareOnTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/start-trip', 'page-center');
  }

  rideShareDropTrip(tourId, data): Observable<any> {
    return this.restApiService.post('', '/secure/ride-share/' + tourId + '/end-trip', data, 'page-center');
  }

  acknowledgeTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/' + tourId + '/acknowledge', 'page-center');
  }

  acknowledgeRideShareTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/acknowledge', 'page-center');
  }

  doPayment(data): Observable<any> {
    return this.restApiService.post('', '/secure/manual-booking/stripe', data, 'page-center');
  }

  getCardDetails(userId): Observable<any> {
    return this.restApiService.get('', `/secure/manual-booking/web-stripe/` + userId, 'page-center');
  }

  getDateFormat() {
    let dateFormatRecord = this.getTenantConfigValue(AppSettings.DATE_FORMAT);
    dateFormatRecord = dateFormatRecord === null ? '' : dateFormatRecord.toLowerCase();
    const check = dateFormatRecord.includes('yyyy');
    if (check) {
      dateFormatRecord = dateFormatRecord.replace('yyyy', 'yy');
    }
    return dateFormatRecord;
  }

  cashCollecte(tripid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/collect-cash/' + tripid, data);
  }

  converDistanceWithTime(distance, time) {
    return (distance / 1000) + ' km - ' + this.convertMinsToHrs(time);
  }

  convertMinsToHrs(time) {
    return (Math.floor(time / 60)) + ' hrs ' + (time % 60) + ' mins';
  }

  timeConvertion(milisec: any) {
    const duration = parseInt(milisec, 10);
    const seconds = Math.ceil((duration / 1000) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    let time;
    if (hours > 0) {
      time = hours + 'h ' + minutes + 'm ' + seconds + 's';
    } else if (minutes > 0) {
      time = minutes + 'm ' + seconds + 's';
    } else if (seconds > 0) {
      time = seconds + 's';
    }
    return time;
  }

  setFullName(firstName, lastName?) {
    let fullName;
    if (lastName) {
      fullName = firstName + ' ' + lastName;
    } else {
      fullName = firstName;
    }
    return fullName;
  }

  setFullPhoneNumber(countryCode, phoneNumber) {
    let fullNumber;
    if (countryCode) {
      fullNumber = countryCode + ' ' + phoneNumber;
    } else {
      fullNumber = phoneNumber;
    }
    return fullNumber;
  }

  setFullAddress(getAddress1, getAddress2, getCity?, getZipcode?) {
    let address1 = '';
    let address2 = '';
    let city = '';
    let zipcode = '';
    if (getAddress1 !== undefined && getAddress1 !== null) {
      address1 = getAddress1;
    }
    if (getAddress2 !== undefined && getAddress2 !== null) {
      address2 = ', ' + getAddress2;
    }
    if (getCity !== undefined && getCity !== null) {
      city = ', ' + getCity;
    }
    if (getZipcode !== undefined && getZipcode !== null) {
      zipcode = ', ' + getZipcode;
    }
    const fullAddress = address1 + address2 + city + zipcode;
    return fullAddress;
  }

  getMilesFromMeter(meterInput) {
    return meterInput * 0.000621371192;
  }

  // code for demo start
  getProviderList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/provider/list/data?searchText=' + searchString, 'page-center');
  }

  getRoleName() {
    const userInfo: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    const userrolename = userInfo.roles[0].roleName;
    const id = userInfo.roles[0].id;
    if (userrolename === 'System Admin' || id === '297452467745826660') {
      return true;
    }
  }
  // code for demo end




  initialiseButtons() {
    this.statusButton = {
      field: 'STATUS',
      class: 'STATUS',
      title: (data) => {
        if (data.activated) {
          return {
            title: this.getLabel('label_deactivate'),
            icon: 'fa fa-ban',
            btnClass: 'btn-deactivate',
          };
        }
        return {
          title: this.getLabel('label_active'),
          icon: 'fa fa-check-circle',
          btnClass: 'btn-activate',
        };
      },
      show: (data) => {
        if (data.activated) {
          return `<span title=${this.getLabel('label_deactivate')}
                class="fa fa-check-circle fa-lg btnstatus activate"></span>`;
        } else {
          return `<span title=${this.getLabel('label_active')}
                class="fa fa-ban fa-lg btnstatus deactivate"></span>`;
        }
      }
    };

    this.statusActiveButton = {
      field: 'STATUS',
      class: 'STATUS',
      title: (data) => {
        if (data.active) {
          return {
            title: this.getLabel('label_deactivate'),
            icon: 'fa fa-ban',
            btnClass: 'btn-deactivate',
          };
        }
        return {
          title: this.getLabel('label_active'),
          icon: 'fa fa-check-circle',
          btnClass: 'btn-activate',
        };
      },
      show: (data) => {
        if (data.active) {
          return `<span title=${this.getLabel('label_deactivate')}
                class="fa fa-check-circle fa-lg status activate"></span>`;
        } else {
          return `<span title=${this.getLabel('label_active')}
                class="fa fa-ban fa-lg status deactivate"></span>`;
        }
      }
    };


    this.editButton = {
      field: 'EDIT',
      class: 'EDIT', show: (data) => {
        // if(data.contactFormStatus !== 430){
        return `<span title=${this.getLabel('label_edit')} class="fa fa-pencil fa-lg view"></span>`;
        // }
      },
      title: () => {
        return {
          title: this.getLabel('label_edit'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.clientUpdatedButton = {
      field: 'EDIT',
      class: 'EDIT', show: (data) => {
        // if(data.contactFormStatus !== 430){
        return `<span title=${this.getLabel('client_updated')} class="">/assets/images/temp/Task.gif</span>`;
        // }
      },
      title: () => {
        return {
          title: this.getLabel('client_updated'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.cancelButton = {
      field: 'CANCEL',
      class: 'CANCEL',
      show: (data) => {
        if (AppSettings.BOOOKING_QUOTATION_ACCEPTED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.ASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.DRIVER_IS_ARRIVED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.DRIVER_IS_ARRIVED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.REASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0) {
          return `<span title=${this.getLabel('label_cancel')} class="fa fa-ban fa-lg status deactivate"></span>`;
        } else {
          return null;
        }
      },
      title: (data) => {
        if (AppSettings.BOOOKING_QUOTATION_ACCEPTED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.ASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.DRIVER_IS_ARRIVED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.DRIVER_IS_ARRIVED.indexOf(data.bookingStatus) >= 0 ||
          AppSettings.REASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0) {
          return {
            title: this.getLabel('label_cancel'),
            icon: 'fa fa-ban fa-lg status deactivate',
          };
        } else {
          return null;
        }
      },
    };

    this.detailsButton = {
      field: 'DETAILS',
      class: 'DETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class=" view"><img src='/assets/images/temp/view.png'/></span>`;
      }
    };

    this.bellButton = {
      field: 'DETAILS',
      class: 'DETAILS',
      title: () => {
        return {
          title: 'Pending',
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title="Reminder" class="pending"><img src='/assets/images/temp/bell.png'/></span>`;
      }
    };

    this.resendNotificationButton = {
      field: 'RESEND',
      class: 'RESEND',
      title: () => {
        return {
          title: 'Resend',
          icon: 'fa fa-paper-plane',
        };
      },
      show: () => {
        return `<span title="Resend" class="resend fa fa-w fa-paper-plane fa-lg"></span>`;
      }
    };

    this.detailsButton2 = {
      field: 'DETAILS',
      class: 'DETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class="view-details view">View Details</span>`;
      }
    };

    this.calendarButton = {
      field: 'SCHEDULE',
      class: 'SCHEDULE',
      title: (data) => {
        if (data.driverTypeId !== '7' && data.loginType !== 'Web Portal') {
          return {
            title: 'View Shift',
            icon: 'fa fa-calendar',
          };
        }
        return null;
      },
      show: (data) => {
        if (data.driverTypeId !== '7' && data.loginType !== 'Web Portal') {
          return `<span title="View Shift" class="edit"><img src='/assets/images/temp/calender.png'/></span>`;
        } else {
          return null;
        }
      }
    };

    this.assignButton = {
      field: 'ASSIGN',
      class: 'ASSIGN',
      title: (data) => {
        if (AppSettings.ASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0 && !data.driverId) {
          return {
            title: 'Assign Provider',
            icon: 'fa fa-fw fa-user',
          };
        } else {
          return null;
        }
      },
      show: (data) => {
        if (AppSettings.ASSIGN_BOOKING_STATUS_ARRAY.indexOf(data.bookingStatus) >= 0 && !data.driverId) {
          return `<span title="Assign Provider" class="fa fa-fw fa-user fa-lg view"></span>`;
        } else {
          return null;
        }
      }
    };


    this.reAssignButton = {
      field: 'REASSIGN',
      class: 'REASSIGN',
      title: (data) => {
        if (AppSettings.REASSIGN_DRIVER_STATUS.indexOf(data.bookingStatus) >= 0 && data.driverId) {
          return {
            title: 'Reassign Provider',
            icon: 'fa fa-fw fa-user',
          };
        } else {
          return null;
        }
      },
      show: (data) => {
        if (AppSettings.REASSIGN_DRIVER_STATUS.indexOf(data.bookingStatus) >= 0 && data.driverId) {
          return `<span title="Reassign Provider" class="fa fa-fw fa-user fa-lg view"></span>`;
        } else {
          return null;
        }
      }
    };

    this.attachmentButton = {
      field: 'ATTACHMENT',
      class: 'ATTACHMENT',
      title: () => {
        return {
          title: 'Attachment',
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('Attachment')} class=" view"><img src='/assets/images/temp/attachment.png'/></span>`;
      }
    };

    this.reRequestButton = {
      field: 'RE-REQUEST',
      class: 'RE-REQUEST',
      title: () => {
        return {
          title: 'RE-REQUEST',
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('Re-Request')} class=" view"><img src='/assets/images/temp/rerequest.png'/></span>`;
      }
    };

    this.callButton = {
      field: 'CALL',
      class: 'CALL',
      title: () => {
        return {
          title: 'Call',
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('CALL')} class=" view"><img src='/assets/images/temp/phone.png'/></span>`;
      }
    };

    this.videoCallButton = {
      field: 'VIDEO-CALL',
      class: 'VIDEO-CALL',
      title: () => {
        return {
          title: 'VIDEO-Call',
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('VIDEO-CALL')} class=" view"><img src='/assets/images/temp/video-camera.png'/></span>`;
      }
    };

    this.unAssignButton = {
      field: 'UN-ASSIGN',
      class: 'UN-ASSIGN',
      title: () => {
        return {
          title: 'UN-ASSIGN',
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        if (data.status.innerText === 'Pending') {
          return `<span title='Unassign' class="unassign view">Unassign</span>`;

        }
      }
    };

    this.reminderButton = {
      field: 'Reminder',
      class: 'Reminder',
      title: () => {
        return {
          title: 'Reminder',
          icon: 'fa fa-bell',
        };
      },
      show: (data) => {
        if (data.status.innerText === 'Pending') {
          return `<span title='Reminder' class="fa fa-bell"></span>`;
        }
      }
    };

    this.downloadButton = {
      field: 'downloadDocuments',
      class: 'downloadDocuments',
      title: () => {
        return {
          title: 'Download',
          icon: 'fa fa-download',
        };
      },
      show: () => {
        return `<span title='Download' class="downloaddoc"><img src='/assets/images/temp/downloaddoc.png'/></span>`;
      }
    };

    this.deleteButton = {
      field: 'DELETE',
      class: 'DELETE',
      title: () => {
        return {
          title: 'DELETE',
          icon: 'fa fa-trash',
        };
      },
      show: () => {
        return `<span title="Delete" class="fa fa-check-circle-o fa-lg delete"></span>`;
      }
    };

    this.screeningTabButton = {
      field: 'ScreeningDETAILS',
      class: 'ScreeningDETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        if (data.formType === 'P.E.A.T.') {
          return `<span title="PEAT: ${data.score}" class="view"><img src='/assets/images/temp/view.png'/></span>`;
        } else {
          return `<span  class=" view"><img src='/assets/images/temp/view.png'/></span>`;
        }
      }
    };

    this.viewMapButton = {
      field: 'VIEWMAP',
      class: 'VIEWMAP',
      title: () => {
        return {
          title: 'Pending',
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        const dispatchConfigValue = this.getTenantConfigValue(AppSettings.DISPATCH_REQUEST_48_HRS);
        // dateFormatRecord = dateFormatRecord === null ? '' : dateFormatRecord.toLowerCase();
        // if (userObj !== undefined && userObj !== null) {
        //   this.userId = userObj.id;
        // }

        const today = new Date();
        const gmt = today.toUTCString();
        const currentTime = new Date(gmt).getTime();
        const milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
        const hoursMS = milliseconds(dispatchConfigValue, 0, 0);
        const totalDateMS = (data.createdAt + hoursMS);
        // 48 hours done for dispatch and sign dispatch value true then map icon not visible
        if (totalDateMS > currentTime && !data.sign && data.dispatchStatusId !== 590) {
          return `<span class="pending" title=${this.getLabel('label_viewmap')}><img src='/assets/images/temp/view-map.png'/></span>`;
        }

      }
    };

    this.cancelReqButton = {
      field: 'CANCELREQ',
      class: 'CANCELREQ',
      title: () => {
        return {
          title: 'Pending',
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        const contactListDate = this.toTimezoneDateFormat(data.contactStartDateTime, data.dispatchTimezone);
        const currentDate = moment();
        const listDate = moment(contactListDate).format('YYYY-MM-DD');
        const todayDate = moment(currentDate).format('YYYY-MM-DD');
        if (data.contactFormStatus !== 430 && data.contactFormStatus !== 410 && (todayDate === listDate ||
          listDate > todayDate || listDate < todayDate) || data.dispatchStatusId === 570 || data.dispatchStatusId === 600) {
          return `<span class="pending" title=${this.getLabel('Dispatch')}><img src='/assets/images/temp/cancel_treck.png'/></span>`;
        }
      }
    };

    this.cancelDispatchRequestButton = {
      field: 'CANCELDISPATCH',
      class: 'CANCELDISPATCH',
      title: () => {
        return {
          title: 'Pending',
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        if (data.dispatchStatusId !== 570 && data.dispatchStatusId !== 600 || data.applicationStatus === 8020) {
          return `<span  class="pending" title=${this.getLabel('label_cancelRequest')}><img src='/assets/images/temp/cancelreq.png'/></span>`;
        }

      }
    };

    this.rejectButton = {
      field: 'REJECT',
      class: 'REJECT',
      title: () => {
        return {
          title: 'Pending',
          icon: 'fa fa-eye',
        };
      },
      show: (data) => {
        if (data.applicationStatus === 8020) {
          return `<span  class="pending" title=${this.getLabel('Reject')}><img src='/assets/images/temp/cancelreq.png'/></span>`;
        }

      }
    };

    this.approveButton = {
      field: 'APPROVE',
      class: 'APPROVE',
      title: () => {
        return {
          title: 'APPROVE',
          icon: 'fa fa-check-circle-o',
        };
      },
      show: (data) => {
        if (data.applicationStatus === 8020) {
          return `<span  class="pending" title=${this.getLabel('Approve')}><img src='/assets/images/temp/approve.png'/></span>`;
        }
      }
    };

  }

  getYearRangeForCalendar() {
    const prevMonth = (new Date().getMonth() === 0) ? 11 : new Date().getMonth() - 1;
    const prevYear = (prevMonth === 11) ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const nextMonth = ((new Date().getMonth()) === 11) ? 0 : (new Date().getMonth()) + 1;
    const nextYear = (nextMonth === 0) ? (new Date().getFullYear()) + 1 : (new Date().getFullYear());
    const range = (prevYear - 150) + ':' + (nextYear + 10);
    return range;
  }

  timeAdjustment(hour: any) {
    const hr = hour[1].split(' ');
    if (hr[0] <= '08' && hr[0] >= '00') {
      hr[0] = '00';
    } else if ((hr[0] <= '15' && hr[0] >= '09') || (hr[0] <= '22' && hr[0] >= '15')) {
      hr[0] = '15';
    } else if ((hr[0] <= '30' && hr[0] >= '23') || (hr[0] <= '38' && hr[0] >= '30')) {
      hr[0] = '30';
    } else if ((hr[0] <= '45' && hr[0] >= '39') || (hr[0] <= '52' && hr[0] >= '45')) {
      hr[0] = '45';
    } else if (hr[0] <= '60' && hr[0] >= '53') {
      hr[0] = '45';
    }
    hour[1] = hr.join(' ');
    const time = hour.join(':');
    return time;
  }

  addOneHourToCurrentTime() {
    const runningMinute = new Date().getMinutes();
    let runninghour = new Date().getHours();
    let settingMinute = 0;
    if (runningMinute >= 0 && runningMinute < 15) {
      settingMinute = this.minuteArray[1];
    } else if (runningMinute >= 15 && runningMinute < 30) {
      settingMinute = this.minuteArray[2];
    } else if (runningMinute >= 30 && runningMinute < 45) {
      settingMinute = this.minuteArray[3];
    } else if (runningMinute >= 45) {
      settingMinute = this.minuteArray[0];
      runninghour = runninghour < 23 ? runninghour + 1 : 0;
    }

    let setDateToTimePicker = new Date().setMinutes(settingMinute);
    setDateToTimePicker = new Date(setDateToTimePicker).setHours(runninghour + 1);
    this.currentDate = new Date(setDateToTimePicker);
    return this.currentDate;
  }
}
